import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import usage_1 from '../../../../assets/img/logos/usage/1.png';
import usage_2 from '../../../../assets/img/logos/usage/2.png';
import usage_3 from '../../../../assets/img/logos/usage/3.png';

const items = [
  { icon: usage_1 }, 
  { icon: usage_2 }, 
  { icon: usage_3 },
];

const Benefits = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('benefits.legend')}
        </Typography>
        <Box
          component={Typography}
          sx={{ fontWeight: 700, whiteSpace: 'pre-line' }}
          variant={'h3'}
          gutterBottom
          align={'center'}
        >
          {t('benefits.title')}
        </Box>
        <Typography align={'center'} color="textSecondary" variant={'h6'}>
          {t('benefits.subtitle')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {
          t('benefits.items', { returnObjects: true }).map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box width={1} height={'100%'} data-aos={'fade-up'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Box
                    component="img"
                    height={'100%'}
                    width={'20%'}
                    src={items[i].icon}
                    alt="..."
                    sx={{
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0) invert(0.7)'
                          : 'none',
                    }}
                  />
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                    align={'center'}
                  >
                    {item.title}
                  </Typography>
                  <Typography align={'center'} color="textSecondary">
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
};

Benefits.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Benefits;
