import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import extension_1 from '../../../../assets/img/logos/extensions/1.png';
import extension_2 from '../../../../assets/img/logos/extensions/2.png';
import extension_3 from '../../../../assets/img/logos/extensions/3.png';
import extension_4 from '../../../../assets/img/logos/extensions/4.png';
import extension_5 from '../../../../assets/img/logos/extensions/5.png';
import extension_6 from '../../../../assets/img/logos/extensions/6.png';

const items = [
  {
    icon: extension_6
  },
  {
    icon: extension_1
  },
  {
    icon: extension_5
  },
  {
    icon: extension_2
  },
  {
    icon: extension_3
  },
  {
    icon: extension_4
  }
];

import { useTranslation } from 'react-i18next';

const Platforms = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('platforms.legend')}
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          {t('platforms.title')}
        </Box>
      </Box>
      <Grid container spacing={2}>
        {
          t('platforms.items', { returnObjects: true }).map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                display={'block'}
                width={'100%'}
                height={'100%'}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={'100%'}
                  height={'100%'}
                  data-aos={'fade-up'}
                  borderRadius={3}
                  flexDirection={'column'}
                  display={'flex'}
                >
                  <Box
                    component={CardContent}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Box
                      component="img"
                      height={'100%'}
                      width={'40%'}
                      src={items[i].icon}
                      alt="..."
                      sx={{
                        filter:
                          theme.palette.mode === 'dark'
                            ? 'brightness(0) invert(0.7)'
                            : 'none',
                      }}
                    />
                    <Box
                      component={Typography}
                      variant={'h6'}
                      gutterBottom
                      fontWeight={500}
                      align={'center'}
                    >
                      {item.title}
                    </Box>
                    <Typography align={'center'} color="textSecondary">
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box component={CardActions} justifyContent={'flex-end'}>
                    <Button 
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    >
                      {t('platforms.action-button')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))
        }
      </Grid>
      <Box marginTop={4}>
        <Grid container spacing={2}>
          {
            t('platforms.metrics', { returnObjects: true }).map((item, i) => (
              <Grid key={i} item xs={12} md={4}>
                <Typography variant="h3" align={'center'} gutterBottom>
                  <Box fontWeight={600}>
                    <VisibilitySensor
                      onChange={(isVisible) => setViewPortVisibility(isVisible)}
                      delayedCall
                    >
                      <CountUp
                        redraw={false}
                        end={viewPortEntered ? Number(item.value) : 0}
                        start={0}
                        suffix={item.suffix}
                      />
                    </VisibilitySensor>
                  </Box>
                </Typography>
                <Typography color="text.secondary" align={'center'} component="p">
                  {item.title}
                </Typography>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default Platforms;
