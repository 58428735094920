import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import logo from '../../../../assets/img/logos/home.png';

import clients from './clients';

import { useTranslation } from 'react-i18next';

const Hero = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              color="textPrimary"
              sx={{ fontWeight: 700 }}
            >
              {t('hero.title')}
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="textSecondary">
              {t('hero.description')}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
          >
          </Box>
          <Box marginTop={{ xs: 4, sm: 6, md: 8 }} textAlign={'left'}>
            <Typography variant="body1" component="p" color="textPrimary">
              {t('hero.legend')}
            </Typography>
            <Box display="flex" flexWrap="wrap" justifyContent={'flex-start'}>
              {clients.map((item, i) => (
                <Box maxWidth={84} marginTop={2} marginRight={4} key={i} component={'a'} href={item.href} target='_blank' rel="noopener noreferrer">
                  <Box
                    component="img"
                    height={'100%'}
                    width={'100%'}
                    src={item.src}
                    alt="..."
                    sx={{
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0) invert(0.7)'
                          : 'none',
                      transition: 'all .3s ease-in',
                      scale: '1',
                      borderRadius: '50%',
                      zIndex: 9,
                      '&:hover': {
                        transition: 'all .3s ease-in',
                        scale: '1.5',
                        filter: 'grayscale(0)',
                        backgroundColor: 'whitesmoke',
                        position: 'relative',
                        zIndex: 10
                      }
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        xs={12}
        md={6}
        data-aos={isMd ? 'fade-left' : 'fade-up'}
      >
        <Box
          component={LazyLoadImage}
          height={'100%'}
          width={'100%'}
          src={logo}
          alt="..."
          effect="blur"
          borderRadius={4}
          maxWidth={600}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
