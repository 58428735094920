import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DawntechLogo from 'svg/logos/Dawntech';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useTranslation } from 'react-i18next';

const Footer = ({ themeMode }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={2}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            underline="none"
            href="/"
            title="Dawntech"
            // height={150}
            maxWidth={150}
          >
            <img src={themeMode === 'light' ? DawntechLogo.ci : DawntechLogo.bi} alt="Dawntech" />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} md={6}>
        <Stack spacing={2}>
          <Typography variant="h4" fontWeight={600}>{t('footer.header')}</Typography>
          <Typography variant="subtitle2">{t('footer.subheader')}</Typography>

          <Box>
            <IconButton href="https://www.linkedin.com/company/dawntech" aria-label="linkedin">
              <LinkedInIcon />
            </IconButton>
            <IconButton href="https://twitter.com/dawntech_dev" aria-label="twitter">
              <XIcon />
            </IconButton>
            <IconButton href="https://www.facebook.com/dawntech.dev" aria-label="facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://www.instagram.com/dawntech.dev" aria-label="instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton href="https://www.youtube.com/dawntech" aria-label="youtube">
              <YouTubeIcon />
            </IconButton>
            <IconButton href="https://www.tiktok.com/@dawntech" aria-label="tiktok">
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path xmlns="http://www.w3.org/2000/svg" d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
              </SvgIcon>
            </IconButton>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={6} md={6}>
              <Stack spacing={2}>
                <Typography variant="subtitle1">{t('navigation.links')}</Typography>
                <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/jobs">{t('navigation.jobs')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/partners">{t('navigation.partners')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/privacy">{t('navigation.privacy')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/plans">{t('navigation.plans')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://appcatalyst.com.br">{t('navigation.catalyst')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/cases">{t('navigation.cases')}</Link>
              </Stack>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack spacing={2}>
                <Typography variant="subtitle1">{t('navigation.for-clients')}</Typography>
                <Link style={{ textDecoration: 'none' }} href="https://client.dawntech.dev">{t('navigation.client+')}</Link>
                <Link style={{ textDecoration: 'none' }} href={'mailto:helpdesk@dawntech.dev?subject=' + t('dawntech_inc') + ' - ' + t('footer.support')}>{t('navigation.support')}</Link>
                <Link style={{ textDecoration: 'none' }} href="https://status.dawntech.dev">{t('navigation.status')}</Link>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Grid item xs={12} my={6} align="center">
        <Box width="250px">
          <a target="_blank" rel="noreferrer" href="https://www.goodfirms.co/directory/country/top-software-development-companies/br">
            <img src={'https://assets.goodfirms.co/badges/' + (themeMode === 'light' ? 'blue' : 'white') + '-button/top-software-development-companies.svg'} alt="GoodFirms Badge" />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
