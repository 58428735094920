import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { Topbar, Footer } from './components';
import Container from 'common/Container';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div>
      <HideOnScroll>
        <AppBar
          position={'fixed'}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
          elevation={1}
        >
          <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
            <Topbar
              themeMode={themeMode}
              themeToggler={themeToggler}
              setThemePalette={setThemePalette}
              paletteType={paletteType}
            />
          </Container>
        </AppBar>
      </HideOnScroll>

      <main>
        <Box height={{ xs: 56 }} />
        {children}
        <Divider />
      </main>
      <Container paddingY={0} paddingTop={4}>
        <Footer themeMode={themeMode}/>
      </Container>
      <Divider>
        <Typography
          align={'center'}
          variant={'subtitle'}
          color="textSecondary"
        >
          <Link style={{ textDecoration: 'none' }} href="https://dawntech.dev/">
            {t('dawntech', { context: 'copyright', year: new Date().getFullYear() })}
          </Link>
        </Typography>
      </Divider>
      <Box sx={{ textAlign: 'center', paddingY: 1 }}>
        <Typography
          align={'center'}
          variant={'overline'}
          color="textSecondary"
          lineHeight={1}
        >
          DAWNTECH CONSULTORIA LTDA
        </Typography>
        <br></br>
        <Typography
          align={'center'}
          variant={'overline'}
          color="textSecondary"
          lineHeight={1}
        >
          CNPJ: 38.179.807/0001-06
        </Typography>
        <br></br>
        <Typography
          align={'center'}
          variant={'overline'}
          color="textSecondary"
          lineHeight={1}
        >
          {t('dawntech', { context: 'address' })}
        </Typography>
        <br></br>
        <Typography
          align={'center'}
          variant={'overline'}
          color="textSecondary"
          lineHeight={1}
        >
          {t('footer.phone')}: <a href='https://wa.me/555135572998' target='blank'>+55 (51) 3557-2998</a> / <a href="tel:+441245790914">+44 (1) 245 790 914</a> / <a href="tel:+525584214909">+52 55 8421 4909</a>
        </Typography>
      </Box>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Main;
